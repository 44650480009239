import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ZoomPluginConfiguration from '../models/zoomPluginConfiguration';
import { MessageService } from '../services/message.service';
import LanguageSelectionConfiguration from '../models/languageSelectionConfiguration';
import { Language } from '../models/language';
import * as _ from 'lodash';
import { ThrowStmt } from '@angular/compiler';
import { MatSelectionListChange } from '@angular/material';

@Component({
  selector: 'app-language-selection-dialog',
  templateUrl: 'language-selection.dialog.component.html',
  styleUrls: ['language-selection.dialog.component.scss']
})
export class LangageSelectionDialogComponent {
  config = new LanguageSelectionConfiguration();
  selectedLanguages: Language[] = [];
  recentLanguages: Language[] = [];
  filter = '';

  constructor(
    public dialogRef: MatDialogRef<LangageSelectionDialogComponent, Language[]>,
    @Inject(MAT_DIALOG_DATA) public data: LanguageSelectionConfiguration
  ) {
    Object.assign(this.config, data);
    Object.assign(this.selectedLanguages, this.config.selectedLanguages);
    this.recentLanguages = this.loadRecentLanguages();

    // if (!this.config.multiple) {
    //   // Clear existing selection when in single selection mode.
    //   this.selectedLanguages = [];
    // }
  }

  onSelectionChanged(event: MatSelectionListChange): void {
    const language = event.option.value;
    if (event.option.selected) {
      this.saveAsRecentLanguage(language);

      if (this.config.multiple) {
        // Add to selected langauges
        if (!this.selectedLanguages.includes(language)) {
          this.selectedLanguages.push(language);
        }
      } else {
        // Set language as the only selected language
        this.selectedLanguages = [language];

        // Single selection mode is active. When selecting a language, the dialog can be closed.
        this.submit();
      }
    } else {
      // Remove from selected languages
      if (this.selectedLanguages.includes(language)) {
        this.selectedLanguages.splice(this.selectedLanguages.indexOf(language), 1);
        //this.selectedLanguages = this.selectedLanguages.slice(language, 0);
      }
    }
  }

  submit(): void {
    this.dialogRef.close(this.selectedLanguages);
  }

  clear(): void {
    this.dialogRef.close(null);
  }

  private loadRecentLanguages(): Language[] {
    const recentJson = localStorage.getItem('recent_languages');
    const recent: Language[] = JSON.parse(recentJson) || [];

    return this.config.availableLanguages.filter(x => recent.find(y => y.code === x.code));
  }

  private saveAsRecentLanguage(language: Language): void {
    let recent = this.loadRecentLanguages();

    // Check, if language is already in recent languages
    if (recent.find(x => x.code === language.code)) {
      return;
    }

    // Add language and reduce array to max size
    recent.unshift(language);
    recent = recent.slice(0, 3);

    // Save
    localStorage.setItem('recent_languages', JSON.stringify(recent));
  }
}
