import { ElementRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import Wave from '@foobar404/wave';

export default class LocalAudioRecordingService {
  private audioContext?: AudioContext;
  private mediaSource?: MediaStreamAudioSourceNode;
  private filter?: BiquadFilterNode;
  private stream?: MediaStream;
  private audioWorkletNode?: AudioWorkletNode;
  private animationCanvas: ElementRef<HTMLCanvasElement>;
  private waveAnimation: any;

  chunkReceived = new Subject<ArrayBuffer>();
  isAudioMonitorOn = new BehaviorSubject<boolean>(false);
  isRecording = false;

  constructor(animationCanvas: ElementRef<HTMLCanvasElement>) {
    this.animationCanvas = animationCanvas;
  }

  async startAsync(): Promise<void> {
    // TODO: If this gets called multiple times, it creates multiple streams which can't be stopped.
    this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    if (!this.stream) {
      console.error('Could not get the audio stream!');
      return;
    }

    // To support Safari, AudioContext has to be declared like this.
    // Details: https://stackoverflow.com/questions/48757933/audiocontext-issue-on-safari
    this.audioContext = new (window['AudioContext'] || window['webkitAudioContext'])({
      latencyHint: 'interactive',
      sampleRate: 16000
    });
    this.audioContext.baseLatency;

    this.mediaSource = this.audioContext.createMediaStreamSource(this.stream);
    this.isRecording = true;

    this.filter = this.audioContext.createBiquadFilter();
    this.filter.type = 'lowpass';
    this.filter.frequency.setValueAtTime(8000, this.audioContext.currentTime);

    await this.audioContext.audioWorklet.addModule('/assets/audio-processor.js');
    this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-processor');
    this.audioWorkletNode.port.onmessage = event => {
      this.chunkReceived.next(event.data);
    };

    this.mediaSource.connect(this.filter);
    this.filter.connect(this.audioWorkletNode);
    this.audioWorkletNode.connect(this.audioContext.destination);

    if (this.isAudioMonitorOn.value) {
      this.startMonitor();
    }

    // Start animation
    this.waveAnimation = new Wave();
    this.waveAnimation.fromStream(
      this.stream,
      this.animationCanvas.nativeElement.id,
      {
        type: 'shine',
        colors: ['#0374ca', 'transparent']
      },
      false
    );
  }

  async stopAsync(): Promise<void> {
    try {
      await this.audioContext.close();
    } catch (error) {
      console.warn('Local audio context can not be closed.', error);
    }

    if (this.waveAnimation) {
      this.waveAnimation.stopStream();
      this.waveAnimation = undefined;
      const canvas = this.animationCanvas.nativeElement;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    }

    try {
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
      }
    } catch (error) {
      console.warn('Local audio stream can not be stopped.', error);
    }

    try {
      if (this.audioWorkletNode) {
        this.audioWorkletNode.port.close();
        this.audioWorkletNode.disconnect();
      }
    } catch (error) {
      console.warn('Local audio worklet node can not be stopped.', error);
    }

    this.isRecording = false;
  }

  toggleAudioMonitor(): void {
    this.isAudioMonitorOn.next(!this.isAudioMonitorOn.value);

    // Activate or deactivate the audio monitor if the recording is currently running.
    if (this.isRecording) {
      this.isAudioMonitorOn.value ? this.startMonitor() : this.stopMonitor();
    }
  }

  private startMonitor(): void {
    this.mediaSource.connect(this.audioContext.destination);
    this.isAudioMonitorOn.next(true);
  }

  private stopMonitor(): void {
    this.mediaSource.disconnect(this.audioContext.destination);
    this.isAudioMonitorOn.next(false);
  }
}
