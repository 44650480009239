export class Beta {
  desktopApp: boolean;
  zoom: boolean;
  speakerRecognition: boolean;

  constructor(beta?: Beta) {
    if (undefined === beta) {
      this.getDefaultValues();
    } else {
      this.desktopApp = beta.desktopApp;
      this.zoom = beta.zoom;
      this.speakerRecognition = beta.speakerRecognition;
    }
  }

  getDefaultValues() {
    this.desktopApp = false;
    this.zoom = false;
    this.speakerRecognition = false;
  }
}
