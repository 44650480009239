import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { VocabularyBook } from '../models/vocabularyBook';
import languageEncoding from 'detect-file-encoding-and-language';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService {
  constructor() {}

  public exportVocabularyBookWords(book: VocabularyBook): void {
    const content = book.words.map(word => word).join('\n');
    const filename = `${book.name}.txt`;
    const blob = new Blob([content], {
      type: 'text/plain'
    });
    saveAs(blob, filename);
  }

  public async importVocabularyBookWords(book: VocabularyBook): Promise<VocabularyBook> {
    // Create and open a file picker
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.accept = 'text/plain';

    return new Promise<VocabularyBook>((resolve, reject) => {
      input.onchange = () => {
        const file = input.files[0];
        languageEncoding(file).then(fileInfo => {
          // Check, if the encoding includes utf-8
          if (fileInfo.encoding === null || !fileInfo.encoding.toLowerCase().includes('utf-8')) {
            reject(new Error('The file is not encoded in UTF-8. Please choose another file or convert it to UTF-8.'));
            return;
          }

          const reader = new FileReader();
          reader.onload = () => {
            const content = reader.result as string;
            const words = content.split('\n');
            words.forEach(word => {
              if (book.words.indexOf(word) === -1) {
                book.words.push(word);
              }
            });

            resolve(book);
          };

          reader.readAsText(file);
        });
      };

      input.click();
    });
  }
}
