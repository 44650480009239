import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListenerService } from 'src/app/services/listener.service';
import { MessageService } from 'src/app/services/message.service';
import { SettingsService } from 'src/app/services/api/settings.service';
import { Settings } from 'src/app/models/settings';
import { DisplaySentence } from 'src/app/models/displaySentence';
import { EventService } from 'src/app/services/api/event.service';
import { Event } from 'src/app/models/event';
import { Language } from 'src/app/models/language';
import { MatDialog } from '@angular/material/dialog';
import { PasswordDialogComponent } from 'src/app/dialogs/password.dialog.component';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/api/language.service';
import { trigger, state, transition, animate, style, stagger } from '@angular/animations';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import ElectronIpc from '../../helpers/electronIpc';
import { ConnectionState } from 'src/app/helpers/connectionState';
import { HostListener } from '@angular/core';
import { FullscreenService } from 'src/app/services/fullscreen.service';
import { DeviceDetectionService } from 'src/app/services/device-detection.service';
import launchContext from 'src/app/models/launchContext';

@Component({
  selector: 'app-listener',
  templateUrl: './listener.component.html',
  styleUrls: ['./listener.component.scss'],
  animations: [
    trigger('isLogoVisibleChanged', [
      state('true', style({ opacity: 1, visibility: 'visible' })),
      state('false', style({ opacity: 0, visibility: 'collapse' })),
      transition('* => *', animate('.5s'))
    ])
  ]
})
export class ListenerComponent implements OnInit, OnDestroy {
  // Grab scrollable div from HTML to scroll it down after new sentences arrive
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  @ViewChildren('sentences') sentenceElements: QueryList<ElementRef>;

  settings: Settings;
  sentences: DisplaySentence[];
  visibleSentences: DisplaySentence[];
  visibleSentencesStartIndex: number;
  maxVisibleSentences: number;

  isSettingsMenuAllowed: boolean;
  isSettingsOpen: boolean;
  isSettingsMenuAvailable: boolean;
  isFullscreen: boolean;
  isMobile: boolean;

  isLogoAllowed: boolean;
  isLogoVisible: boolean;
  isButtonsVisible: boolean;

  language: Language;
  event: Event;
  availableLanguages: Language[];
  textDirection: string;
  subscription: Subscription;
  allSentencesVisible: boolean;

  private subscriptionManager = new SubscriptionManager();
  private displayFromIndex = 0;
  private readonly electronIpc: ElectronIpc;
  private previousConnectionState = ConnectionState.Disconnected;

  constructor(
    private route: ActivatedRoute,
    private listenerService: ListenerService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private eventService: EventService,
    private languageService: LanguageService,
    private fullscreenService: FullscreenService,
    private deviceDetectionService: DeviceDetectionService,
    public dialog: MatDialog
  ) {
    this.settings = new Settings();
    this.electronIpc = new ElectronIpc();
    this.sentences = [];
    this.event = new Event();

    this.isSettingsMenuAllowed = false;
    this.isSettingsOpen = false;
    this.isSettingsMenuAvailable = false;
    this.isFullscreen = false;
    this.isMobile = this.deviceDetectionService.getIsMobile();

    this.isLogoAllowed = false;
    this.isLogoVisible = true;
    this.isButtonsVisible = true;

    this.availableLanguages = [];
    this.textDirection = 'ltr';

    this.maxVisibleSentences = 50;
    this.visibleSentences = [];
    this.allSentencesVisible = true;
  }

  async ngOnInit() {
    try {
      const eventUrl = this.route.snapshot.paramMap.get('url');
      const delay = this.route.snapshot.queryParams.delay;

      // Load event
      this.event = await this.eventService.getEventAsync(eventUrl);

      // Load availabe translation languages
      if (this.event.hasTranslation) {
        this.availableLanguages = await this.languageService.getTranslationLanguagesAsyncFor(
          this.event.translationLanguages
        );
        this.isSettingsMenuAvailable = true;
      } else {
        this.isSettingsMenuAvailable = false;
        this.availableLanguages = [];
      }

      // Handle modes (Electron, Teams, Web, ...) to load settings
      if (this.electronIpc.enabled) {
        // Notify Electron about changed event
        this.electronIpc.invokeEventChanged(this.event, this.availableLanguages);

        this.settings = await this.electronIpc.getSettings();

        // Hide logo and settings menu in electron mode
        this.isLogoAllowed = false;
        this.isSettingsMenuAllowed = false;
      } else if (launchContext.isTeamsMode) {
        this.settings = await this.settingsService.getSettings();
        this.isSettingsMenuAllowed = false;
        this.isLogoAllowed = true;
      } else {
        this.settings = await this.settingsService.getSettings();
        this.isLogoAllowed = true;
        this.isSettingsMenuAllowed = this.route.snapshot.queryParams.hideSettings
          ? !JSON.parse(this.route.snapshot.queryParams.hideSettings)
          : true;
      }

      // Load availabe translation languages
      // This has to be done, after the settings are loaded
      if (this.event.hasTranslation) {
        this.setDefaultTranslationLanguage(this.settings.defaultTranslationLanguageCode);
      }

      // Override settings from URL parameters
      this.overrideSettingsFromUrlParameters();

      await this.listenerService.connectAsync(this.event);

      if (delay) {
        this.listenerService.delay = +delay;
      }
    } catch (error) {
      this.messageService.showToast(error.message);
    }

    this.promptForPassword();
    this.subscribe();
  }

  ngOnDestroy() {
    this.listenerService.stop();
    this.subscriptionManager.unsubscribeAll();
  }

  private subscribe() {
    this.subscriptionManager.add(
      // Subscribe to new sentences
      this.listenerService.behaviorSentences.subscribe(sentences => this.onSentencesChanged(sentences)),
      this.listenerService.connectionState.subscribe(connectionState => this.onConnectionStateChanged(connectionState)),
      this.sentenceElements.changes.subscribe(_ => this.scrollToBottom())
    );

    if (this.electronIpc && this.electronIpc.enabled) {
      this.subscriptionManager.add(
        this.electronIpc.languageChanged.subscribe((language: Language) => {
          this.language = language;
          this.listenerService.changeTranslationLanguage(this.language);
        })
      );
    }
  }

  onConnectionStateChanged(connectionState: ConnectionState): void {
    switch (connectionState) {
      case ConnectionState.Reconnecting:
        this.messageService.showToast('Reconnecting...', 0);
        break;
      case ConnectionState.Disconnected:
        this.messageService.showReloadToast('Connection lost.', 0);
        break;
      case ConnectionState.Connected:
        if (this.previousConnectionState === ConnectionState.Reconnecting) {
          this.messageService.showToast('Connected.');
        }
        break;
    }

    this.previousConnectionState = connectionState;
  }

  enterFullscreen(): void {
    this.fullscreenService.enter();
  }

  exitFullscreen(): void {
    this.fullscreenService.leave();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isFullscreen = this.fullscreenService.enabled;
  }

  private promptForPassword(): void {
    if (!this.event.hasPassword) {
      return;
    }
    if (this.electronIpc.enabled) {
      this.getPasswordFromElectron();
    } else {
      this.dialog.open(PasswordDialogComponent);
    }
  }

  private async getPasswordFromElectron(repeat = false): Promise<void> {
    const subscriptionManager = new SubscriptionManager();

    subscriptionManager.add(
      this.listenerService.passwordConfirmed$.subscribe(message => {
        subscriptionManager.unsubscribeAll();
      }),
      this.listenerService.passwordErrored$.subscribe(message => {
        subscriptionManager.unsubscribeAll();
        this.getPasswordFromElectron(true);
      })
    );

    const password = await this.electronIpc.invoke('getPassword', repeat);
    if (!password) {
      // TODO: how to handle if the user clicks cancel???
    }
    await this.listenerService.validateEventPassword(password);
  }

  private onSentencesChanged(sentences: DisplaySentence[]) {
    this.sentences = sentences;
    const lastSentence = sentences[sentences.length - 1];
    if (lastSentence) {
      this.textDirection = lastSentence.textDirection;
    }

    // Update logo visibility
    this.isLogoVisible = this.sentences.length === 0;

    // Update visible sentences
    this.updateVisibleSentences(this.sentences);
  }

  private updateVisibleSentences(sentences: DisplaySentence[]) {
    // Render all sentences from displayFromIndex on
    this.visibleSentences = this.sentences.filter((s, i) => i >= this.displayFromIndex);

    // Reset sentences and ignore maximum sentence limit, if less sentences than the maximum limit
    // are in the list. This is the case, when the recording just started or the screen was cleared.
    if (this.sentences.length <= this.maxVisibleSentences) {
      this.displayFromIndex = 0;
      this.allSentencesVisible = true;
      return;
    }

    // Check, if maxVisibleSentences limit has been exceeded
    if (this.visibleSentences.length > this.maxVisibleSentences && this.sentenceElements.length > 0) {
      // Get first sentence that stays visible and save its indentation offset
      const stayIndex = this.visibleSentences.length - this.maxVisibleSentences;
      const staySentence = this.visibleSentences[stayIndex];
      const staySentenceElement = this.sentenceElements.toArray()[stayIndex];
      const left = staySentenceElement.nativeElement.offsetLeft;
      const right = staySentenceElement.nativeElement.offsetRight;

      // Remove all previous sentences
      this.visibleSentences.splice(0, 1);
      this.displayFromIndex = this.sentences.indexOf(staySentence);
      this.allSentencesVisible = false;

      // Apply old indentation offstet to the remaining sentence
      const padding = parseFloat(
        window.getComputedStyle(this.scrollFrame.nativeElement, null).getPropertyValue('padding')
      );
      if (staySentence.textDirection === 'ltr') {
        staySentenceElement.nativeElement.style.marginLeft = left - padding + 'px';
      } else {
        staySentenceElement.nativeElement.style.marginRight = right + padding + 'px';
      }
    }
  }

  private scrollToBottom() {
    this.scrollFrame.nativeElement.scroll({
      top: this.scrollFrame.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  private setDefaultTranslationLanguage(translationLanguageCode: string) {
    if (translationLanguageCode) {
      const defaultTranslationLanguage = this.availableLanguages.find(x => x.code === translationLanguageCode);
      if (defaultTranslationLanguage) {
        this.language = defaultTranslationLanguage;
        this.onLanguageChanged();
      }
    }
  }

  private overrideSettingsFromUrlParameters() {
    const fontFamily = this.route.snapshot.queryParams.fontFamily;
    const fontSize = this.route.snapshot.queryParams.fontSize;
    const fontColor = this.route.snapshot.queryParams.fontColor;
    const fontTransform = this.route.snapshot.queryParams.fontTransform;
    const backgroundColor = this.route.snapshot.queryParams.backgroundColor;
    const useOutlineColor = this.route.snapshot.queryParams.useOutlineColor === 'true';
    const outlineColor = this.route.snapshot.queryParams.outlineColor;
    const defaultTranslationLanguageCode = this.route.snapshot.queryParams.translation;

    this.settings.override(
      fontFamily,
      fontSize,
      fontColor,
      fontTransform,
      backgroundColor,
      useOutlineColor,
      outlineColor,
      defaultTranslationLanguageCode
    );

    // Update default translation language
    this.setDefaultTranslationLanguage(defaultTranslationLanguageCode);
  }

  toggleSettings() {
    this.isSettingsOpen = !this.isSettingsOpen;
  }

  onLanguageChanged() {
    this.listenerService.changeTranslationLanguage(this.language);
  }
}
