import { Component, OnInit, OnDestroy } from '@angular/core';
import { VocabularyBook, VocabularyBookStateUtil, VocabularyBookState } from 'src/app/models/vocabularyBook';
import { GlobalsService } from 'src/app/services/globals.service';
import { MessageService } from 'src/app/services/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { VocabularyBookService } from 'src/app/services/api/vocabulary-book.service';
import { Location } from '@angular/common';
import { Language } from 'src/app/models/language';
import { VocabularyBookInUseError } from 'src/app/errors/vocabulary-book-in-use-error';
import { LanguageService } from 'src/app/services/api/language.service';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImportExportService } from 'src/app/services/import-export.service';
import launchContext from '../../models/launchContext';

@Component({
  selector: 'app-create-vocabulary-book',
  templateUrl: './create-vocabulary-book.component.html',
  styleUrls: ['./create-vocabulary-book.component.scss']
})
export class CreateVocabularyBookComponent implements OnInit, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly DEFAULT_LANGUAGE = 'en-US';

  isLoading: boolean;
  isDirty: boolean;
  book: VocabularyBook;
  state: string;
  isInTraining: boolean;
  isTrained: boolean;
  progressBarModel: string;
  progressBarValue: number;
  isNewBook: boolean;
  availableLanguages: Language[];
  selectedLanguage = new Language();
  showBackButton = false;

  private subscriptionManager = new SubscriptionManager();
  private statusUpdateTimer: any;

  bookForm = new FormGroup({
    bookNameFormControl: new FormControl('', [Validators.required]),
    wordsFormControl: new FormControl([], [Validators.required])
  });

  get bookNameFormControl() {
    return this.bookForm.get('bookNameFormControl');
  }

  get wordsFormControl() {
    return this.bookForm.get('wordsFormControl');
  }

  constructor(
    public globals: GlobalsService,
    private messageService: MessageService,
    private vocabularyBookService: VocabularyBookService,
    private languageService: LanguageService,
    private importExportService: ImportExportService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.book = new VocabularyBook();
    this.book.language = this.DEFAULT_LANGUAGE;
    this.book.createdAt = new Date();
    this.isNewBook = true;
    this.isLoading = false;
    this.isDirty = false;
    this.availableLanguages = [];
    this.showBackButton = launchContext.isTeamsMode;
  }

  async ngOnInit() {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));

    // Load available languages
    try {
      this.globals.setIsLoading(true);
      this.availableLanguages = await this.languageService.getRecordingLanguagesAsync();
      this.selectedLanguage = this.availableLanguages.find(x => x.code === this.DEFAULT_LANGUAGE);
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }

    // Load existing book
    const bookId = this.route.snapshot.paramMap.get('id');
    if (bookId != null) {
      this.isNewBook = false;

      try {
        this.globals.setIsLoading(true);
        this.book = await this.vocabularyBookService.getBookAsync(bookId);
        this.book = await this.vocabularyBookService.getBookAsync(bookId, true);
        this.wordsFormControl.setValue(this.book.words);
        this.selectedLanguage = this.availableLanguages.find(x => x.code === this.book.language);
      } catch (error) {
        this.messageService.showToast(error.message);
      } finally {
        this.globals.setIsLoading(false);
      }

      // Update book status
      this.updateTrainingStatus(this.book);

      // Create timer to update book status regularly
      this.statusUpdateTimer = setInterval(async () => {
        console.log('Updating vocabulary book status...');
        const updatedBook = await this.vocabularyBookService.getBookAsync(this.book.id);
        this.updateTrainingStatus(updatedBook);
      }, 60000);
    }
  }

  async createBook() {
    try {
      this.globals.setIsLoading(true);
      this.book.words = this.wordsFormControl.value;
      this.book = await this.vocabularyBookService.createBookAsync(this.book);
      this.router.navigate(['/book', this.book.id]);
    } catch (error) {
      this.messageService.showToast(error);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async updateBook() {
    // Check if language or words have been changed
    if (this.isDirty && this.book.state === VocabularyBookState.succeeded) {
      const proceed = await this.showTrainingResetWarningAsync();
      if (!proceed) {
        return;
      }
    }

    try {
      this.globals.setIsLoading(true);
      this.book.words = this.wordsFormControl.value;
      this.book = await this.vocabularyBookService.changeBookAsync(this.book);
      this.updateTrainingStatus(this.book);
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  addWord(event: MatChipInputEvent): void {
    this.isDirty = true;
    const input = event.input;
    const value = event.value.trim();

    if (value.length === 0) {
      return;
    }

    // Check, if word already exists
    if (this.wordsFormControl.value.indexOf(value) >= 0) {
      this.messageService.showToast('"' + value + '" already exists in your vocabulary book.');
      return;
    }

    // Add word
    this.wordsFormControl.value.push(value);
    this.wordsFormControl.updateValueAndValidity();

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeWord(word: string): void {
    this.isDirty = true;
    const index = this.wordsFormControl.value.indexOf(word);

    if (index >= 0) {
      this.wordsFormControl.value.splice(index, 1);
      this.wordsFormControl.updateValueAndValidity();
    }
  }

  navigateBack() {
    this.router.navigate(['/']);
  }

  async startTrainingAsync() {
    const proceed = await this.messageService.showActionDialogAsync(
      'Training costs 30 minutes',
      'Regardless the actual time a training takes, a training always costs 30 minutes. ' +
        'When starting the training, we will reduce your booked recording time by 30 minutes. Training for vocabulary books that are not used for 14 days will be reset automatically.',
      'Start training',
      'Cancel'
    );

    if (!proceed) {
      return;
    }

    try {
      this.globals.setIsLoading(true);
      this.book = await this.vocabularyBookService.startTrainingAsync(this.book);
      this.updateTrainingStatus(this.book);
      this.messageService.showToast(
        'The Training can take up to 60 minutes. We will notify you via E-Mail, when the training is done.'
      );
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  languageChanged(language: Language) {
    this.isDirty = true;
    this.selectedLanguage = language;
    this.book.language = language.code;
  }

  onSelectedLanguageChanged(languages: Language[]) {
    if (languages.length > 0) {
      this.isDirty = true;
      this.book.language = languages[0].code;
    }
  }

  private updateTrainingStatus(book: VocabularyBook) {
    // Set book status
    this.book.state = book.state;
    this.state = 'Training ' + VocabularyBookStateUtil.toString(book.state);
    this.isInTraining =
      this.book.state === VocabularyBookState.training || this.book.state === VocabularyBookState.creatingEndpoint;
    this.progressBarModel = this.book.state === VocabularyBookState.training ? 'indeterminate' : 'determinate';
    this.progressBarValue = this.book.state === VocabularyBookState.succeeded ? 100 : 0;
    this.isTrained = this.book.state === VocabularyBookState.succeeded;
  }

  private showTrainingResetWarningAsync(): Promise<boolean> {
    return this.messageService.showActionDialogAsync(
      'New training will be required',
      'Changing language or words will require a new training of the vocabulary book. ' + 'Do you want to proceed?',
      'Continue',
      'Cancel'
    );
  }

  async deleteVocabularyBook() {
    const result = await this.messageService.showActionDialogAsync(
      'Do you really want to delete this vocabulary book?',
      'This will reset your training. You can restore it from the trash at any time but will ' +
        'have to restart the training then.',
      'Move to trash',
      'Cancel'
    );

    if (result === true) {
      try {
        this.globals.setIsLoading(true);
        const book = await this.vocabularyBookService.deleteBookAsync(this.book, true);
        this.messageService.showToast('Moved vocabulary book "' + book.name + '" to trash.');
        this.location.back();
      } catch (error) {
        if (error instanceof VocabularyBookInUseError) {
          await this.messageService.showDialogAsync('Vocabulary book still in use', error.message, 'Ok');
        } else {
          this.messageService.showToast(error.message);
        }
      } finally {
        this.globals.setIsLoading(false);
      }
    }
  }

  exportVocabularyBook(): void {
    this.importExportService.exportVocabularyBookWords(this.book);
  }

  async importVocabularyBook(): Promise<void> {
    try {
      this.book = await this.importExportService.importVocabularyBookWords(this.book);
      this.wordsFormControl.setValue(this.book.words);
    } catch (error) {
      this.messageService.showToast(error.message);
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
    clearInterval(this.statusUpdateTimer);
  }
}
