import { Sentence } from './sentence';

export class CorrectorSentence extends Sentence {
  isApprovalTimerRunning: boolean;
  public approvalTimerInPercent: number;
  private approvalTimer: any;
  public isLocked: boolean;
  private timerIsActive: boolean;
  public isFocussed: boolean;

  public get isTimerActive(): boolean {
    return this.timerIsActive;
  }

  constructor(sentence: Sentence) {
    // Copy everything from the original sentence over
    super(
      sentence.id,
      sentence.eventId,
      sentence.language,
      sentence.creation,
      sentence.order,
      sentence.text,
      sentence.originalText,
      sentence.translations,
      sentence.isComplete,
      sentence.isApproved,
      sentence.textDirection,
      sentence.speakerId,
      sentence.start,
      sentence.end
    );

    // Init CorrectorSentence specific properties
    this.approvalTimerInPercent = 0;
    this.isApprovalTimerRunning = false;
    this.isLocked = false;
  }

  /**
   * Updates the current values with the one from the incoming sentence without overriding
   * the approval timer etc.
   * @param sentence Sentence to update from
   */
  updateFrom(sentence: Sentence) {
    this.order = sentence.order;
    this.text = sentence.text;
    this.originalText = sentence.originalText;
    this.isComplete = sentence.isComplete;
    this.isApproved = sentence.isApproved;
    this.start = sentence.start;
    this.end = sentence.end;
  }

  /**
   * Creates an auto-approval timer for this sentence, but does not start it yet.
   * Calls a method after it has finished.
   * @param lengthInSeconds Timer duration in seconds
   * @param finished Method to call after timer has finished
   */
  createApprovalTimer(lengthInSeconds: number, finished: Function) {
    // Make sure, the timer is not running
    this.isApprovalTimerRunning = false;

    // Calculate step size for the approval bar
    const stepSize = 100 / lengthInSeconds;

    // Create and save timer
    this.approvalTimer = setInterval(() => {
      if (!this.isApprovalTimerRunning) {
        return;
      }

      // Increase percentage value
      this.approvalTimerInPercent += stepSize;

      // If timer has reached 100%, fire finished() and remove timer
      if (this.approvalTimerInPercent >= 100) {
        clearInterval(this.approvalTimer);
        this.timerIsActive = false;
        finished();
      }
    }, 1000);
  }

  startApprovalTimer() {
    this.isApprovalTimerRunning = true;
    this.timerIsActive = true;
  }

  pauseApprovalTimer() {
    this.isApprovalTimerRunning = false;
  }

  approve() {
    this.isApproved = true;
    this.approvalTimerInPercent = 100;
    this.timerIsActive = false;
    this.pauseApprovalTimer();
  }

  lock(): void {
    this.isLocked = true;
  }

  unlock(): void {
    this.isLocked = false;
  }

  focus(): void {
    this.isFocussed = true;
  }

  unFocus(): void {
    this.isFocussed = false;
  }
}
