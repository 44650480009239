import { Component, OnInit, OnDestroy } from '@angular/core';
import { SettingsService } from 'src/app/services/api/settings.service';
import { MessageService } from 'src/app/services/message.service';
import { Settings } from 'src/app/models/settings';
import { GlobalsService } from 'src/app/services/globals.service';
import { SubscriptionManager } from 'src/app/helpers/subscriptionManager';
import { LoggerService } from 'src/app/services/logger.service';
import { UserService } from 'src/app/services/api/user.service';
import { MatCheckboxChange, MatDialog } from '@angular/material';
import { getQueryParameters } from '../../helpers/queryParametersHelpers';
import ElectronIpc from '../../helpers/electronIpc';
import * as copy from 'copy-to-clipboard';
import { UrlParametersDialogComponent } from 'src/app/dialogs/url-parameters.dialog.component';
import { Language } from 'src/app/models/language';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  settings: Settings;
  isLoading: boolean;
  isTransparentBackgroundAllowed: boolean;
  showUrlParametersButton = true;
  translationLanguages: Language[] = [];

  private subscriptionManager = new SubscriptionManager();
  private readonly queryParameters: Map<string, string>;
  private readonly electronIpc: ElectronIpc;

  constructor(
    private globals: GlobalsService,
    private settingsService: SettingsService,
    private messageService: MessageService,
    private loggerService: LoggerService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    this.settings = new Settings();
    this.isTransparentBackgroundAllowed = false;
    this.electronIpc = new ElectronIpc();

    // Electron
    if (this.electronIpc.enabled) {
      console.log('Opening settings in Electron mode');
      // Hide header
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(`.mat-toolbar{ display: none !important; }`));
      document.getElementsByTagName('head')[0].appendChild(style);

      // Desktop App specific settings
      this.isTransparentBackgroundAllowed = true;
      this.showUrlParametersButton = false;
    }
  }

  private handleQueryParameters(): void {
    if (this.electronIpc.enabled) {
    }
    console.log('Welcome Electron!');

    let cssRules = '';
    if (this.queryParameters.has('withoutHeader')) {
      cssRules += `
        .mat-toolbar{
          display: none !important;
        }
      `;
    }

    // Add css rules to DOM
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(cssRules));
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  async ngOnInit() {
    this.subscriptionManager.add(this.globals.getIsLoading().subscribe(value => (this.isLoading = value)));

    try {
      this.globals.setIsLoading(true);

      if (this.electronIpc.enabled) {
        this.settings = await this.electronIpc.getSettings();
      } else {
        // Get current user's settings, if a user is logged in.
        // If not, use settings from cookies or default settings.
        this.settings = await this.settingsService.getSettings();
      }
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  onSelectedDefaultTranslationLanguagesChanged(languages: Language[]) {
    if (languages.length > 0) {
      this.settings.defaultTranslationLanguageCode = languages[0].code;
    } else {
      this.settings.defaultTranslationLanguageCode = null;
    }
  }

  async save() {
    try {
      this.globals.setIsLoading(true);
      if (this.electronIpc.enabled) {
        this.electronIpc.send('updateSettings', this.settings);
      } else {
        await this.settingsService.saveSettings(this.settings);
      }
      this.messageService.showToast('Settings saved for current user.');
    } catch (error) {
      this.messageService.showToast(error.message);
    } finally {
      this.globals.setIsLoading(false);
    }
  }

  async restore() {
    const confirm = await this.messageService.showActionDialogAsync(
      'Restore default settings',
      'Are you sure you want to restore the default settings? All changes to the settings will be lost.',
      'Restore defaults',
      'Cancel'
    );
    if (!confirm) {
      return;
    }

    this.settings = new Settings();
    if (this.electronIpc.enabled) {
      this.settings.backgroundColor = 'transparent';
    }
    await this.save();
  }

  generateUrlParams(): void {
    const params = this.settings.generateUrlParams();
    this.dialog.open(UrlParametersDialogComponent, {
      data: params
    });
  }

  async allowTrackingChanged(changed: MatCheckboxChange) {
    // Activate or deactivate detailed user tracking
    const currentUser = await this.userService.getCurrentUserAsync();
    if (changed.checked) {
      this.loggerService.setUser(currentUser.userId, currentUser.loggingLevel);
    } else {
      this.loggerService.clearUserContext();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribeAll();
  }
}
